import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function logisticscarriagerUseList() {
  // Use toast
  const toast = useToast()
  const condition = ref({})
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: ''},
    { key: 'id', label: 'ID', sortable: true },
    { key: 'carriage_name', label: '承运人', sortable: true },
    { key: 'car_type', label: '车辆类型', sortable: true },
    { key: 'car_no', label: '车牌号', sortable: true },
    { key: 'car_cycle', label: '车型', sortable: true },
    { key: 'check_weight', label: '核定载重(T)', sortable: true },
    { key: 'load_length', label: '装载长度(M)', sortable: true },
    { key: 'load_width', label: '装载宽度(M)', sortable: true },
    { key: 'load_height', label: '装载高度(M)', sortable: true },
    { key: 'car_driver', label: '司机姓名', sortable: true },
    { key: 'car_tel', label: '司机电话', sortable: true },
    { key: 'car_driver_second', label: '副司机姓名', sortable: true },
    { key: 'car_tel_second', label: '副司机电话', sortable: true },
    { key: 'car_owner', label: '车主姓名', sortable: true },
    { key: 'car_owner_tel', label: '车主电话', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'updator', label: '操作人', sortable: true },
    { key: 'modify_time', label: '操作日期', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('carriage_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('logisticscarriager/search', {
        carriage_name: condition.value.carriage_name,
        car_type: condition.value.car_type,
        car_no:condition.value.car_no,
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    refetchData,

    // Extra Filters
  }
}
